app.directive('altNumeric', function (){
    return {
		require: 'ngModel',
		link: function (scope, element, attrs, controller){

			// precisão padrão de dois dígitos
			var apenasInteiros = false, zerosPrecisao = '00', padZeros = true, siglaMoeda = 'R$ ', valorMaximo = '999999999.99', aceitaVazio = false, usaMascara = true, retornaString = false;
			
			var options = scope.$eval(attrs.altNumeric);
			
			if(options){
				if(options.apenasInteiros) apenasInteiros = true;

				if(options.precisao && options.precisao > 0) zerosPrecisao = new Array(options.precisao + 1).join('0');

				if(options.suprimeZerosAposCasaDecimal) padZeros = false;

				if(options.suprimeSiglaMoeda) siglaMoeda = '';

				if(options.valorMaximo) valorMaximo = options.valorMaximo;

				if(options.aceitaVazio) aceitaVazio = true;

				if(options.usaMascara===false) usaMascara = false;

				if(options.retornaString) retornaString = true;
			}

			controller.$render = function (){
				var value = controller.$viewValue || (aceitaVazio ? '' : 0);
				element.autoNumeric('set', value);
				return value;
			};

			controller.$parsers.push(function (){
				return (retornaString ? element.autoNumeric('get') : parseFloat(element.autoNumeric('get')));
			});

			element.bind("focus", function (){
				if(element.autoNumeric('get') == 0){
					if(!aceitaVazio) element.autoNumeric('set', '');
					// posiciona o cursor no final do input
					//setElementSelection(element[0], element.val().length, element.val().length);
				}
			});

			element.bind("blur focusout" ,function (){
				if(element.val() == "" && aceitaVazio){
					element.autoNumeric('set', '');
				}
				else if(element.val() == "" && !aceitaVazio){
					element.autoNumeric('set', 0);
				}
								
				scope.$apply(function (){
					return controller.$setViewValue(element.val());
				});
			});

			element.bind("keyup change" ,function (){
				scope.$apply(function (){
					if(element.autoNumeric('get') != '')
						return controller.$setViewValue(element.val());
				});
			});

			if(apenasInteiros && usaMascara)
				element.autoNumeric('init', { aSep: '.', aDec: ',', aSign: siglaMoeda, vMin: '0', aPad: padZeros, vMax: valorMaximo });
			else if(apenasInteiros && !usaMascara)
				element.autoNumeric('init', { aSep: '', aDec: ',', aSign: siglaMoeda, vMin: '0', aPad: padZeros, vMax: valorMaximo });
			else if(!apenasInteiros && !usaMascara)
				element.autoNumeric('init', { aSep: '', aDec: ',', aSign: siglaMoeda, vMin: '0.' + zerosPrecisao, aPad: padZeros, vMax: valorMaximo });
			else
				element.autoNumeric('init', { aSep: '.', aDec: ',', aSign: siglaMoeda, vMin: '0.' + zerosPrecisao, aPad: padZeros, vMax: valorMaximo });
		}
	};
});