app.service('$localStorage', ['$window', '$timeout', function($window, $timeout){
	'use strict';
	var storage = $window.localStorage;

	return {
		exists: function(key){
			if(this.getLength() > 0 && storage.getItem(key))
				return true;

			return false;
		},
		get: function(key){
			if(!key)
				return storage;

			if(this.exists(key)){
				var item = storage.getItem(key);
				//isJson function from consts.js lib
				return (isJson(item) ? angular.fromJson(item) : item);
			}

			return null;
		},
		set: function(key, value){
			if(this.exists(key)){
				this.remove(key);
			}

			var item = (typeof value === 'object') ? angular.toJson(value) : value;
			storage.setItem(key, item);
		},
		remove: function(key){
			if(this.exists(key)){
				storage.removeItem(key);					
				return true;
			}

			return false;
		},
		getLength: function(){
			return storage.length;
		},
		clear: function(){
			storage.clear();
		}
	};
}]);