function retira_acentos(palavra) {
    //IMPORTANTE: Esta funcao esta implementada tambem na diretiva do 
    //menu principal e devem ser atualizadas simultaneamente
    if (palavra) {
        palavra = palavra.toLowerCase();
        palavra = palavra.replace(/[áàâãä]/g,'a').replace(/[éèêë]/g,'e').replace(/[íìîï]/g,'i').replace(/[óòôõö]/g,'o').replace(/[úùûü]/g,'u').replace(/[ç]/g,'c');
        palavra = palavra.replace(/ /gi, '-');
        //remove caracteres especiais
        palavra = palavra.replace(/[^a-zA-Z0-9-_+]/g, '');

        return palavra;      
    }
    return "";
}

function retira_espacos(palavra) {
    if (palavra) {
        palavra = palavra.replace(/ /gi, '-');

        return palavra;      
    }
    return "";
}

Number.prototype.formatMoney = function(c, d, t){
    var n = this;
	c = isNaN(c = Math.abs(c)) ? 2 : c;
	d = d === undefined ? "." : d; 
	t = t === undefined ? "," : t;
	var s = n < 0 ? "-" : "", 
	i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
	j = (j = i.length) > 3 ? j % 3 : 0;
    
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };

function DataFormatada(dataOriginal){
    var value = dataOriginal.substr(6,4) + '-' + dataOriginal.substr(3,2) + '-' + dataOriginal.substr(0,2);
    return value;
}

function ValidarNumeroTel(numero){
    if(numero.substr(1,2) == "00")
        return false;
    if(numero.substr(5,1) == "0")
        return false;
    if(numero.length == 14 && numero.substr(5,4) == "0000" && numero.substr(10,4) == "0000")
        return false;
    if(numero.length == 15 && numero.substr(5,1) != "9")
        return false;
    if(numero.length == 15 && numero.substr(5,5) == "00000" && numero.substr(11,4) == "0000")
        return false;

    return true;
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}