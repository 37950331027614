app.directive('ngBtnGoanchor', function(){
	return {
		scope: {
			targetid: '=',
		}, 
		restrict: 'A', 
		link: function(scope, iElm, iAttrs, controller) {
			iElm.on('click', function(){
				var target = iElm.parents().find('#Topicos').find(scope.targetid);
				angular.element('body, html').animate({scrollTop: target.offset().top}, 1000);
			});
		}
	};
});
