var app = angular.module('app', ['ngSanitize', 'ui.bootstrap']);

//Inicialização do app
app.run(
    ['$rootScope', 
        function($rootScope){

            var self = $rootScope;

            self.loadState = {
                running: true,
                init: function(){
                    this.running = true;
                },
                finish: function(){
                    this.running = false;
                }
            };

            self.alert = {
                default: false,
                type: null,//success, danger, warning, info
                title: null,
                messages: null,
                show: false,
                create: function(type, messages){
                    self.alert.messages = messages;
                    self.alert.type = type;

                    switch(self.alert.type){
                        case 'success':
                            self.alert.title = "Sucesso!";
                            break;
                        case 'danger':
                            self.alert.title = "Atenção!";
                            break;
                        case 'warning':
                            self.alert.title = "Verifique!";
                            break;
                        case 'info':
                            self.alert.title = "Importante!";
                            break;
                    }

                    self.alert.show = true;
                    angular.element("html, body").animate({ scrollTop: $(document).height() + 120 }, 1000);
                },
                destroy: function(){
                    self.alert.show = false;
                    self.alert.messages = [];
                    self.alert.type = null;
                    self.alert.title = null;
                    self.alert.messages = null;                
                }
            };

            self.showResponseError = function(response){
                var messages = ['Erro ao excutar a tarefa. Entre em contato com a administração do sistema.'];
                //console.log(response);

                if(response && response.status == 401 && response.statusText == 'Unauthorized'){
                    window.location.href = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
                }
                else if(response && response.data && response.status == 422){
                    
                    //get keys
                    var keys = [];
                    for(var k in response.data) {
                        keys.push(k);
                    }

                    messages = [];

                    for (var i = 0; i < keys.length; i++) {
                        if(response.data[keys[i]] && response.data[keys[i]].length > 0){
                            for (var j = 0; j < response.data[keys[i]].length; j++) {
                                messages.push(response.data[keys[i]][j]);
                            }
                        }
                    }
                    
                    self.alert.create('danger', messages);
                }
                else{
                    self.alert.default = true;
                    self.alert.create('danger', messages);
                }


            };
                
        }
    ]
);