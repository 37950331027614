app.directive('fileUpload', function() {
    return {
        scope: {
            base64Only: "=?"
        },
        require:"ngModel",
        restrict: 'A',
        link: function($scope, element, attrs, ngModel){
            element.bind('change', function(event){

                var files = event.target.files;

                if(files && files.length > 0)
                {
                    var objFile = {
                        fileName: element.val().substring(this.value.lastIndexOf('\\') + 1, this.value.lenght),
                        fileType: element.val().substring(this.value.lastIndexOf('.') + 1, this.value.lenght),
                        fileData: null,//Visualisar imagem antes do upload
                        fileData2: $scope.base64Only ? null : files[0],//Arquivo para upload
    					fileSize: files[0].size//Arquivo para upload
                    };

    				var reader = new FileReader();
    				reader.onload = function (e) {
    					objFile.fileData = e.target.result;
                        //Grava o objeto na variavel ng-model do controller
                        angular.element('body').focus();
                        $scope.image = angular.copy(objFile);
                        ngModel.$setViewValue($scope.image);
                        $scope.$apply();

                        //console.log($scope.image);
    				};
    				reader.readAsDataURL(files[0]);
                }
            });
        }
    };
});