app.directive('btnComprarScroll', ['$window', function($window){
	// Runs during compile
	return {
		// controller: function($scope, $element, $attrs, $transclude) {},
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, iAttrs, controller) {
			//Menu comprar flutuante
			angular.element($window).bind('scroll',function(){
				if(angular.element('.produto-foco-comprar').length>0)
				{
					if(angular.element($window).scrollTop() > angular.element('.produto-foco-comprar').offset().top + 46){
						iElm.show();
					}else{
						iElm.hide();
					}
				}
			});
		 	if(!$scope.$$phase) $scope.$apply();
		}
	};
}]);