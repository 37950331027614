app.factory('$orcamentoService', 
	['$http', 
		function ($http) {
			'use strict';
			return {
				getEscolas: function(sucesso, falha){					
					$http.get('app/escolas/all').then(sucesso, falha);					
				},//fim
				getTurmas: function(escola_id, sucesso, falha){
					$http.get('app/turmas/' + escola_id).then(sucesso, falha);					
				},//fim
				getLista: function(escola_id, turma_id, sucesso, falha){
					$http.get('app/lista/' + escola_id + '/' + turma_id).then(sucesso, falha);					
				},//fim
				getProdutos: function(sucesso, falha){					
					$http.get('app/produtos').then(sucesso, falha);					
				},//fim
				save: function(orcamento, sucesso, falha){
					$http.post('app/save',
						{ "orcamento": orcamento },
						{ }
					).then(sucesso, falha);					
				}//fim
			};
		}
	]
);