app.directive('altDatepicker', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            // inputs de data terão o tamanho fixo de 80 px,
            // isto foi feito para eveitar que o tamanho do input seja alterado
            // em resoluções inferiores
            //$(element)[0].style.width = '80px';

            var sizeClass;
            if(element.hasClass('input-sm'))
                sizeClass = 'btn-sm';
            if(element.hasClass('input-lg'))
                sizeClass = 'btn-lg';

            element.addClass('alt-datepicker input-datepicker');
            element.wrap('<div class="input-append input-group" />');
            element.datepicker({ 
                dateFormat: 'dd/mm/yy', 
                altFormat: 'dd/mm/yy', 
                showOtherMonths: true, 
                autoSize: false, 
                showOn: "button",
                dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado','Domingo'],
                dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
                dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
                monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
                monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
                nextText: 'Próximo',
                prevText: 'Anterior'
            }).
            next('.ui-datepicker-trigger').
            wrap('<span class="input-group-btn">').
            addClass('btn btn-default alt-datepicker botao-datepicker ' + sizeClass).
            //attr('data-tab-stop', 'false').
            html('<i class="fa fa-calendar"></i>');

            element.click(function (event) {
                event.stopPropagation();
            });

            element.keydown(function (e) {
                if (e.keyCode == 113) { //F2
                    if (element.val().replace(/\D/gi, "") == "") {
                        element.datepicker('setDate', null);
                    }
                    element.datepicker('show');
                }
            }).change(function () {
                scope.$apply(function () {
                    return controller.$setViewValue(element.val());
                });
            });

            scope.$watch(function () {
                // ATENÇÃO a comparação com true é necessária neste ponto
                // pois o valor pode ser undefined e nesse caso deve ser retornado falso.
                return scope.$eval(attrs.ngDisabled) == true;
            }, function (val) {
                var btn = element.next('.ui-datepicker-trigger');
                btn.prop('disabled', val);
            });
        }
    };
});
