app.directive('ngPopover', function(){
	// Runs during compile
	return {
		scope: {
			campo: '=',
			lado: "="
		},
		link: function($scope, iElm, iAttrs, controller) {
			//Popover ajuda
			iElm.popover({
				container: "#PagamentoContainer",
				html: true,
				trigger: 'hover',
				placement: 'bottom',
				content: function(){return '<div class="popover-lado">' + $scope.lado + ' do cartão</div><div class="'+ $scope.campo + '"></div>';}
			});
		}
	};
});