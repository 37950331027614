app.directive('altMask', function(){
	// Runs during compile
	return {
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		require: "?ngModel",
		scope: {
			masktype: '='
		},
		link: function(scope, iElm, iAttrs, ngModel) {

			var AplicarMascara = function(){
				switch(scope.masktype)
				{
					case "data":
						iElm.mask("99/99/9999",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "cpf":
						iElm.mask("999.999.999-99",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "cnpj":
						iElm.mask("99.999.999/9999-99",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "cep":
						iElm.mask("99999-999",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "cep-loja":
						iElm.mask("99999-999",{placeholder:" "});
						iElm.bind('keypress', function(e){
							if(e.keyCode == 13){
								var cep = iElm.val().replace(/\D/g, '');
								if(cep == ""){
									ngModel.$setViewValue(iElm.val());																								
									iElm.trigger('focus');
								}
								else
									ngModel.$setViewValue(iElm.val());															
							}
						});
						break;
					case "tel":
						iElm.mask("(99) 9999-9999",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "cel":
						iElm.mask("(99) 99999-999?9",{placeholder:" "});
						iElm.on('focus',function(){
							iElm.unmask();
							iElm.mask("(99) 99999-999?9",{placeholder:" "});
						}).on('focusout',function(){							
							var phone = iElm.val().replace(/\D/g, '');

							if(phone.length > 10){
								iElm.mask("(99) 99999-9999");								
								ngModel.$setViewValue(iElm.val());														
							}						
							else if(phone.length == 10){
								iElm.mask("(99) 9999-9999");
								ngModel.$setViewValue(iElm.val());						
							}
							else{
								ngModel.$setViewValue(null);
							}
						});
						break;
					case "cpfcnpj":	
						iElm.mask("99999999999?999",{placeholder:" "});				
						iElm.on('focus',function(){
							iElm.unmask();
							iElm.mask("99999999999?999",{placeholder:" "});													
						}).on('focusout',function(){							
							var doc = iElm.val().replace(/\D/g, '');

							if(doc.length == 11){						
								iElm.mask("999.999.999-99");
								ngModel.$setViewValue(iElm.val());								
							}
							else if(doc.length == 14){
								iElm.mask("99.999.999/9999-99");
								ngModel.$setViewValue(iElm.val());
							}
							else{
								ngModel.$setViewValue(null);
							}
							
						});
						break;
					case "braspag_m_id":
						iElm.mask("********-****-****-****-************",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
					case "validadeCartao":
						iElm.mask("99/99",{placeholder:" "});
						iElm.on('focusout', function(){
							ngModel.$setViewValue(iElm.val());
						});
						break;
				}
			}

			AplicarMascara();
			
			//Caso o tipo de mascara seja um valor dinâmico, a mascara tbm é atualizada dinamicamente
			scope.$watch('masktype', function(){
				AplicarMascara();
			});
		}
	};
});