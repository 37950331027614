app.controller('listaController', ['$compile', '$scope', '$rootScope', '$timeout', '$filter', '$orcamentoService', '$modal', '$localStorage',
    function($compile, $scope, $rootScope, $timeout, $filter, $orcamentoService, $modal, $localStorage) {
        var init = function() {
            $scope.orcamento = $localStorage.get('orcamento');

            if ($scope.orcamento === null)
                $scope.orcamento = newOrcamento();

            $scope.orcamentoEnviando = false;
            $scope.orcamentoConcluido = false;
            $scope.lista = null;

            getEscolas();

            //Watcher para salvar no localstorage
            $scope.$watch('orcamento', function(newValue, oldValue) {
                if (newValue) {
                    $localStorage.set('orcamento', $scope.orcamento);
                }
            }, true);
        };


        var newOrcamento = function() {
            return {
                escola: null,
                turma: null,
                itens: null,
                total: 0,
                etapa: 1,
                nome: "",
                email: "",
                cpfcnpj: "",
                rgie: "",
                telefone: "",
                celular: "",
                observacoes: "",
                endereco: {
                    logradouro: "",
                    numero: "",
                    complemento: "",
                    bairro: "",
                    cidade: "",
                    estado: "",
                    cep: ""
                },
                separacaoImediata: false
            };
        };

        var getEscolas = function() {
            $rootScope.loadState.init();

            //limpar a central de alertas
            $rootScope.alert.destroy();

            $scope.escolas = [];

            $orcamentoService.getEscolas(
                function(response) {
                    if (response.data && response.data.escolas)
                        $scope.escolas = response.data.escolas;

                    //adiciona Lista de cliente avulso - Sem escola
                    $scope.escolas.push({
                        id: -1,
                        nome: 'Outros'
                    });

                    $rootScope.loadState.finish();
                },
                function(response) {
                    $rootScope.showResponseError(response);
                    $rootScope.loadState.finish();
                }
            );
        };

        $scope.getTurmas = function(escola_id) {
            if (escola_id > -1) {
                $rootScope.loadState.init();

                //limpar a central de alertas
                $rootScope.alert.destroy();

                $orcamentoService.getTurmas(
                    escola_id,
                    function(response) {
                        if (response.data && response.data.turmas)
                            $scope.turmas = response.data.turmas;

                        $rootScope.loadState.finish();
                    },
                    function(response) {
                        $rootScope.showResponseError(response);
                        $rootScope.loadState.finish();
                    }
                );
            }
        };

        var getLista = function() {
            $rootScope.loadState.init();

            //limpar a central de alertas
            $rootScope.alert.destroy();

            $orcamentoService.getLista(
                $scope.orcamento.escola.id,
                $scope.orcamento.turma.id,
                function(response) {
                    if (response.data && response.data.lista)
                        orcamentoInit(response.data.lista);

                    $rootScope.loadState.finish();
                },
                function(response) {
                    $rootScope.showResponseError(response);
                    $rootScope.loadState.finish();
                }
            );
        };

        var orcamentoInit = function(lista) {
            if (!$scope.orcamento)
                $scope.orcamento = newOrcamento();

            $scope.orcamento.escola = lista.escola;
            $scope.orcamento.turma = lista.turma;
            $scope.orcamento.itens = lista.itens;
        };

        var stageValidate = function(stage) {
            switch (stage) {
                case 1:

                    if (!$scope.orcamento.escola) {
                        $rootScope.alert.create('danger', ['Selecione a escola.']);
                        return false;
                    }

                    if ($scope.orcamento.escola.id > -1 && !$scope.orcamento.turma) {
                        $rootScope.alert.create('danger', ['Selecione a turma.']);
                        return false;
                    }

                    break;
                case 2:

                    if (!$scope.orcamento.itens || $scope.orcamento.itens.length <= 0) {
                        $rootScope.alert.create('danger', ['Informe ao menos um produto para orçamento.']);
                        return false;
                    }

                    for (var i = 0; i < $scope.orcamento.itens.length; i++) {
                        if (!$scope.orcamento.itens[i].selected) {
                            $rootScope.alert.create('danger', ['Selecione o modelo desejado para todos os itens da lista.']);
                            return false;
                        }
                    }

                    break;
                case 3:
                    if (!$scope.orcamento.nome || $scope.orcamento.nome === '') {
                        $rootScope.alert.create('danger', ['Informe seu nome completo.']);
                        return false;
                    }

                    if (!$scope.orcamento.email || $scope.orcamento.email === '') {
                        $rootScope.alert.create('danger', ['Informe seu endereço de e-mail.']);
                        return false;
                    }

                    if (!$scope.orcamento.cpfcnpj || $scope.orcamento.cpfcnpj === '') {
                        $rootScope.alert.create('danger', ['Informe o número do seu CPF ou CNPJ.']);
                        return false;
                    }

                    if ($scope.orcamento.cpfcnpj.length != 14 && $scope.orcamento.cpfcnpj.length != 18) {
                        $rootScope.alert.create('danger', ['Informe um número de CPF ou CNPJ válido.']);
                        return false;
                    }

                    if ($scope.orcamento.cpfcnpj.length == 14 && (!$scope.orcamento.rgie || $scope.orcamento.rgie === '')) {
                        $rootScope.alert.create('danger', ['Informe o número da sua identidade.']);
                        return false;
                    }

                    if ($scope.orcamento.cpfcnpj.length == 18 && (!$scope.orcamento.rgie || $scope.orcamento.rgie === '')) {
                        $rootScope.alert.create('danger', ['Informe o número da inscrição estadual.']);
                        return false;
                    }

                    if (!$scope.orcamento.celular || $scope.orcamento.celular === '' || $scope.orcamento.celular === '(  )      -    ') {
                        $rootScope.alert.create('danger', ['Informe o número do seu celular.']);
                        return false;
                    }

                    if (!$scope.orcamento.itens || $scope.orcamento.itens.length <= 0) {
                        $rootScope.alert.create('danger', ['Selecione produtos para realizar o orçamento.']);
                        return false;
                    }

                    //endereco
                    if (!$scope.orcamento.endereco.logradouro || $scope.orcamento.endereco.logradouro === '') {
                        $rootScope.alert.create('danger', ['Informe o logradouro (Rua, Avenida, Praça...).']);
                        return false;
                    }

                    if ($scope.orcamento.endereco.numero === null || $scope.orcamento.endereco.numero === '') {
                        $rootScope.alert.create('danger', ['Informe o número do endereço.']);
                        return false;
                    }

                    if (!$scope.orcamento.endereco.bairro || $scope.orcamento.endereco.bairro === '') {
                        $rootScope.alert.create('danger', ['Informe o bairro.']);
                        return false;
                    }

                    if (!$scope.orcamento.endereco.cidade || $scope.orcamento.endereco.cidade === '') {
                        $rootScope.alert.create('danger', ['Informe a cidade.']);
                        return false;
                    }

                    if (!$scope.orcamento.endereco.estado || $scope.orcamento.endereco.estado === '') {
                        $rootScope.alert.create('danger', ['Informe o estado.']);
                        return false;
                    }

                    if (!$scope.orcamento.endereco.cep || $scope.orcamento.endereco.cep === '' || $scope.orcamento.endereco.cep === '     -   ') {
                        $rootScope.alert.create('danger', ['Informe o CEP.']);
                        return false;
                    }

                    break;
            }

            return true;
        };

        $scope.nextStage = function() {
            $rootScope.loadState.init();

            //limpar a central de alertas
            $rootScope.alert.destroy();

            var stage = $scope.orcamento.etapa;

            if (stageValidate(stage)) {
                $scope.orcamento.etapa = stage + 1;

                if ($scope.orcamento.etapa == 2 && $scope.orcamento.escola.id > -1)
                    getLista();

                angular.element("html, body").animate({ scrollTop: 0 }, 600);

                if ($scope.orcamento.etapa != 2) {
                    $timeout(function() {
                        $rootScope.loadState.finish();
                    }, 1000);
                }
            } else {
                $rootScope.loadState.finish();
            }

        };

        $scope.backStage = function() {
            $scope.orcamento.etapa--;
        };

        $scope.addItem = function(produto) {
            if (!$scope.orcamento.itens || $scope.orcamento.itens.length <= 0)
                $scope.orcamento.itens = [];

            var item = newItem();
            item.produto = angular.copy(produto);

            $scope.orcamento.itens.push(item);

            //gerar efeito de adicionado com icone verde
            produto.added = true;

            if ($scope.addedListener)
                $timeout.cancel($scope.addedListener);

            $scope.addedListener = $timeout(function() {
                produto.added = false;
            }, 350);
        };

        $scope.copyItem = function(indice) {
            var item = angular.copy($scope.orcamento.itens[indice]);
            item.selected = null;
            $scope.orcamento.itens.splice(indice + 1, 0, item);

            $timeout(function() {
                angular.element('#item-' + (indice + 1)).addClass('copied');
            }, 0);

            $timeout(function() {
                angular.element('#item-' + (indice + 1)).removeClass('copied');
            }, 1000);
        };

        $scope.removeItem = function(indice) {
            if ($scope.orcamento.itens && $scope.orcamento.itens.length > 0) {
                if ($scope.orcamento.itens.length > 1)
                    $scope.orcamento.itens.splice(indice, 1);
                else
                    $rootScope.alert.create('danger', ['A lista deve conter ao menos um item selecionado.']);
            }
        };

        $scope.validateQuantidade = function(item) {
            if (item.quantidade <= 0)
                item.quantidade = 1;
        };

        $scope.decreaseItemQtd = function(item) {
            if (item.quantidade > 1)
                item.quantidade--;
        };

        $scope.increaseItemQtd = function(item) {
            item.quantidade++;
        };

        //variacoes
        $scope.selectVariacao = function(item, indice, collapseIndice) {
            var element = angular.element('#item-selecao-' + collapseIndice);
            item.selected = angular.copy(item.produto.variacoes[indice]);
            element.collapse('hide');
        };

        $scope.removeSelectedVariacao = function(item, collapseIndice) {
            var element = angular.element('#item-selecao-' + collapseIndice);
            item.selected = null;
            element.collapse('show');
        };

        $scope.orcamentoValorTotal = function() {
            var total = 0;

            if ($scope.orcamento.itens && $scope.orcamento.itens.length > 0) {
                for (var i = 0; i < $scope.orcamento.itens.length; i++) {
                    if ($scope.orcamento.itens[i].selected) {
                        total += ($scope.orcamento.itens[i].quantidade * $scope.orcamento.itens[i].selected.preco);
                    }
                }
            }

            $scope.orcamento.total = $filter('currency')(total, 'R$ ', 2);

            return total;
        };

        $scope.saveOrcamento = function() {
            $rootScope.loadState.init();

            $scope.orcamentoEnviando = true;

            //limpar a central de alertas
            $rootScope.alert.destroy();

            if (stageValidate($scope.orcamento.etapa)) {
                $orcamentoService.save(
                    $scope.orcamento,
                    function(response) {
                        //registra o evento no google analytics
                        gtag('event', 'orcamentoFinalizado', {
                            // Event parameters
                            'valor': $scope.orcamento.total
                        });

                        $scope.orcamento = newOrcamento();

                        $scope.orcamentoEnviando = false;
                        $scope.orcamentoConcluido = true;
                        angular.element("html, body").animate({ scrollTop: 0 }, 600);

                        $rootScope.loadState.finish();
                    },
                    function(response) {
                        $rootScope.showResponseError(response);
                        $scope.orcamentoEnviando = false;
                        $rootScope.loadState.finish();
                    }
                );
            } else {
                $scope.orcamentoEnviando = false;
                $rootScope.loadState.finish();
            }

        };

        $scope.createNewOrcamento = function() {
            $scope.orcamentoConcluido = false;
            angular.element("html, body").animate({ scrollTop: 0 }, 600);
        };

        $scope.resetOrcamento = function() {
            $scope.orcamento = newOrcamento();
            $scope.orcamentoEnviando = false;
            $scope.orcamentoConcluido = false;
            $rootScope.loadState.finish();
        };

        //produtos
        $scope.produto = {
            lista: [],
            selected: null,
            openModal: function() {
                $scope.modalInstanceProdutos = $modal.open({
                    templateUrl: 'ModalProdutos.html',
                    scope: $scope
                });

                //obter listagem de produtos
                $rootScope.loadState.init();

                //limpar a central de alertas
                $rootScope.alert.destroy();

                $orcamentoService.getProdutos(
                    function(response) {
                        if (response.data && response.data.produtos)
                            $scope.produto.lista = response.data.produtos;

                        $rootScope.loadState.finish();
                    },
                    function(response) {
                        $rootScope.showResponseError(response);
                        $rootScope.loadState.finish();
                    }
                );

            },
            closeModal: function() {
                for (var i = 0; i < $scope.produto.lista.length; i++) {
                    $scope.produto.lista[i].selected = false;
                }

                if ($scope.modalInstanceProdutos)
                    $scope.modalInstanceProdutos.close();
            },
            addToOrcamento: function() {
                if (!$scope.orcamento.itens || $scope.orcamento.itens.length <= 0)
                    $scope.orcamento.itens = [];

                for (var i = 0; i < $scope.produto.lista.length; i++) {
                    if ($scope.produto.lista[i].selected === true) {
                        $scope.orcamento.itens.push({
                            id: 0,
                            lista_id: $scope.orcamento.itens[0] ? $scope.orcamento.itens[0].lista_id : 0,
                            produto: $scope.produto.lista[i],
                            produto_id: $scope.produto.lista[i].id,
                            quantidade: 1,
                            selected: null
                        });
                    }
                }

                $scope.produto.closeModal();
            }
        };

        $scope.createEconomicList = function() {
            for (var i = 0; i < $scope.orcamento.itens.length; i++) {
                var economicItem = null;

                for (var j = 0; j < $scope.orcamento.itens[i].produto.variacoes.length; j++) {
                    if (economicItem === null) {
                        economicItem = angular.copy($scope.orcamento.itens[i].produto.variacoes[j]);
                    } else {
                        if (+$scope.orcamento.itens[i].produto.variacoes[j].preco < +economicItem.preco) {
                            economicItem = null;
                            economicItem = angular.copy($scope.orcamento.itens[i].produto.variacoes[j]);
                        }
                    }
                }

                $scope.orcamento.itens[i].selected = economicItem;
                var element = angular.element('#item-selecao-' + i);
                element.collapse('hide');
            }
        };

        $scope.separacaoImediata = function(){
            $timeout(function(){
                $scope.orcamento.separacaoImediata = !$scope.orcamento.separacaoImediata;
            }, 0);
        };

        init();
    }
]);